import api from '../utils/api';
import { setAlert } from './alert';
import {
  UPDATE_TRADING_SETTINGS,
  GET_TRADING_SETTINGS,
  TRADING_SETTINGS_ERROR
} from './types';

export const updateTradingSettings = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/trading-settings/`, formData);
    dispatch({
      type: UPDATE_TRADING_SETTINGS,
      payload: res.data
    });
    dispatch(setAlert('Save Trading Settings', 'success'));
  } catch (err) {
      dispatch({
        type: TRADING_SETTINGS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getTradingSettings = () => async (dispatch) => {
    try {
      const res = await api.get('/trading-settings/');
      dispatch({
        type: GET_TRADING_SETTINGS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: TRADING_SETTINGS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };