import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTradingSettings, updateTradingSettings } from '../../actions/trading';

const Trading = ({ updateTradingSettings, getTradingSettings, tradingSettings: { enabled, threshold, priceChangeWindow, safetyWindow } }) => {
  const [formData, setFormData] = useState({
    enabled: false, 
    threshold: 0,
    priceChangeWindow: 0,
    safetyWindow: 0,
  });

  useEffect(() => {
    getTradingSettings();
  }, [getTradingSettings]);

  useEffect(() => {
    setFormData({
      enabled,
      threshold,
      priceChangeWindow,
      safetyWindow,
    });
  }, [enabled, threshold, priceChangeWindow, safetyWindow]);


  const onChange = (e) => {
    const { name, type, value, checked } = e.target;
    let parsedValue = value;
    let isValid = true;

    if (type === 'number') {
      if (name === 'threshold') {
        parsedValue = parseFloat(value) || 0.0;
        if (isNaN(parsedValue)) {
            isValid = false;
        }
      } else {
        parsedValue = parseInt(value) || 0;
        if (isNaN(parsedValue)) {
            isValid = false;
        }
      }
    } else if (type === 'checkbox') {
        parsedValue = checked;
    }

    if (isValid) {
        setFormData({
          ...formData,
            [name]: parsedValue,
        });
    } else {
        console.log("Invalid input for " + name);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateTradingSettings(formData);
  };

  return (
    <section className="container">
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="checkbox"
            id="enabled"
            name="enabled"
            checked={formData.enabled}
            onChange={onChange}
          />
          <label htmlFor="enabled">Enabled</label>
        </div>
        <div className="form-group">
            <input
            type="number"
            placeholder="Threshold"
            name="threshold"
            value={formData.threshold} 
            onChange={onChange}
            step="0.001"
          />
        </div>
        <div className="form-group">
            <input
            type="number"
            placeholder="Price Change Window"
            name="priceChangeWindow"
            value={formData.priceChangeWindow}
            onChange={onChange}
            step="1"
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Safety Window"
            name="safetyWindow"
            value={formData.safetyWindow}
            onChange={onChange}
            step="1"
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Save" />
      </form>
    </section>
  );
};

Trading.propTypes = {
    updateTradingSettings: PropTypes.func.isRequired,
    getTradingSettings: PropTypes.func.isRequired,
    tradingSettings: PropTypes.object.isRequired,
  };
  
const mapStateToProps = (state) => ({
    tradingSettings: state.tradingSettings,
});

export default connect(mapStateToProps, { updateTradingSettings, getTradingSettings })(Trading);