import {
  GET_TRADING_SETTINGS,
  TRADING_SETTINGS_ERROR,
  UPDATE_TRADING_SETTINGS
} from '../actions/types';

const initialState = {
  enabled: false,
  threshold: 0,
  priceChangeWindow: 0,
  safetyWindow: 0,
  loading: true,
  error: {}
};

function tradingSettingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TRADING_SETTINGS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case UPDATE_TRADING_SETTINGS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case TRADING_SETTINGS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}

export default tradingSettingsReducer;
